<template>
  <main>
    <div class="page-header pb-10 page-header-dark bg-primary">
      <div class="container-fluid">
        <div class="page-header-content">
          <h1 class="page-header-title">
            <div class="page-header-icon">
              <i singleBusiness-feather="filter"></i>
            </div>
            <span>Booked Policy</span>
          </h1>
          <div class="page-header-subtitle ml-2">View Booked Policy</div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-n10">
      <div class="card mb-4">
        <div v-if="!pageLoading" class="card-header">
          Policy :- {{ singleBusiness.policyno }}

          <button
            v-if="
              (singleBusiness.coverScope == 'MARINE SINGLE TRANSIT' &&
                singleBusiness.tranrsactionreference == null) ||
              singleBusiness.tranrsactionreference == ''
            "
            @click.prevent="pay()"
            class="btn btn-primary ml-auto"
          >
            Make Payment
          </button>

          <button
            v-if="
              singleBusiness.coverScope == 'MARINE SINGLE TRANSIT' &&
              singleBusiness.tranrsactionreference != null &&
              singleBusiness.tranrsactionreference != ''
            "
            @click.prevent="
              printDiv(
                singleBusiness.coverScope == 'MARINE SINGLE TRANSIT'
                  ? 'certificate2'
                  : singleBusiness.coverScope == 'MARINE OPEN COVER'
                  ? 'certificate'
                  : ''
              )
            "
            class="btn btn-primary ml-auto"
          >
            Print Certificate
          </button>

          <button
            v-if="singleBusiness.coverScope == 'MARINE OPEN COVER'"
            class="btn btn-primary ml-auto"
            data-toggle="modal"
            data-target="#additionalCertificate"
          >
            Buy Additional Certificate
          </button>

          <button
            v-if="
              singleBusiness.tranrsactionreference != null &&
              singleBusiness.tranrsactionreference != ''
            "
            type="submit"
            @click.prevent="
              printDiv(
                singleBusiness.coverScope == 'MARINE SINGLE TRANSIT'
                  ? 'singletransitpolicy'
                  : singleBusiness.coverScope == 'MARINE OPEN COVER'
                  ? 'opencoverpolicy'
                  : ''
              )
            "
            class="btn btn-primary mx-3"
          >
            Print Policy Document
          </button>
        </div>
        <div class="card-body">
          <div v-if="pageLoading">
            <img
              style="width: 30%"
              class="d-block mx-auto"
              src="@/assets/loader.gif"
            />
          </div>
          <div v-else class="form">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group w-100">
                  <label for="">Insured Type</label
                  ><select
                    v-model="singleBusiness.insuredType"
                    class="form-control"
                    id="insurancetype"
                    disabled
                  >
                    <option value="">Select Insured Type</option>
                    <option value="Individual">Individual</option>
                    <option value="Corporate">Corporate</option>
                  </select>
                </div>

                <div
                  class="row"
                  v-if="singleBusiness.insuredType == 'Individual'"
                >
                  <div class="form-group col-md-4">
                    <label for="">Insured Name</label
                    ><input
                      class="form-control"
                      id="insuredName"
                      type="text"
                      v-model="singleBusiness.insuredName"
                      placeholder="Enter Name"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Phone Number</label
                    ><input
                      class="form-control"
                      id="phonenumber"
                      type="text"
                      v-model="singleBusiness.phoneNo"
                      placeholder="Enter Phone Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Email Address</label
                    ><input
                      class="form-control"
                      id="email"
                      type="email"
                      v-model="singleBusiness.email"
                      placeholder="Enter Email"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-2">
                    <label for="">State Of Residence</label>
                    <input
                      class="form-control"
                      id="stateOfResidence"
                      type="text"
                      v-model="singleBusiness.stateOfResidence"
                      placeholder="Enter stateOfResidence"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-2">
                    <label for="">LGA</label
                    ><input
                      class="form-control"
                      id="lga"
                      type="text"
                      v-model="singleBusiness.lga"
                      placeholder="Enter lga"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-8">
                    <label for="">Address</label
                    ><input
                      class="form-control"
                      id="address"
                      type="text"
                      v-model="singleBusiness.address"
                      placeholder="Enter Address"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="">Date Of Birth</label
                    ><input
                      class="form-control"
                      id="dateofbirth"
                      type="text"
                      v-model="singleBusiness.dateOfBirth.split('T')[0]"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Occupation</label>
                    <input
                      v-model="singleBusiness.occupation"
                      disabled
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Marketing Staff</label
                    ><input
                      v-model="singleBusiness.mktStaff"
                      disabled
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Means Of Identification</label
                    ><input
                      type="text"
                      v-model="singleBusiness.meansOfId"
                      class="form-control"
                      id="meansofid"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Identification Number</label
                    ><input
                      class="form-control"
                      id="idnumber"
                      type="text"
                      v-model="singleBusiness.idNumber"
                      placeholder="Enter ID Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">BVN</label
                    ><input
                      class="form-control"
                      id="tin"
                      type="text"
                      v-model="singleBusiness.tin"
                      placeholder="Enter TIN"
                      disabled
                    />
                  </div>
                </div>

                <div
                  class="row"
                  v-if="singleBusiness.insuredType == 'Corporate'"
                >
                  <div class="form-group col-md-4">
                    <label for="">Insured Name</label
                    ><input
                      class="form-control"
                      id="insuredname"
                      type="text"
                      v-model="singleBusiness.insuredName"
                      placeholder="Enter insuredname"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's First Name</label
                    ><input
                      class="form-control"
                      id="firstname"
                      type="text"
                      v-model="singleBusiness.directorFirstname"
                      placeholder="Enter First Name"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Last Name</label
                    ><input
                      class="form-control"
                      id="lastname"
                      type="text"
                      v-model="singleBusiness.directorSurname"
                      placeholder="Enter Last Name"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Other Name</label
                    ><input
                      class="form-control"
                      id="othername"
                      type="text"
                      v-model="singleBusiness.directorOthername"
                      placeholder="Enter Other Name"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Phone Number</label
                    ><input
                      class="form-control"
                      id="phonenumber"
                      type="text"
                      v-model="singleBusiness.directorPhoneNumber"
                      placeholder="Enter Phone Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Email Address</label
                    ><input
                      class="form-control"
                      id="email"
                      type="email"
                      v-model="singleBusiness.directorEmail"
                      placeholder="Enter Email"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Date Of Birth</label
                    ><input
                      class="form-control"
                      id="dateofbirth"
                      type="text"
                      v-model="singleBusiness.directorDateOfBirth.split(' ')[0]"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Means Of Identification</label
                    ><select
                      v-model="singleBusiness.directorMeansOfId"
                      class="form-control"
                      id="meansofid"
                      disabled
                    >
                      <option value="">
                        Director's Means Of Identification
                      </option>
                      <option value="Passport">Passport</option>
                      <option value="NIN">NIN</option>
                      <option value="Drivers License">Drivers License</option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Director's Identification Number</label
                    ><input
                      class="form-control"
                      id="idnumber"
                      type="text"
                      v-model="singleBusiness.directorIdNumber"
                      placeholder="Enter ID Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="">Company Phone Number</label
                    ><input
                      class="form-control"
                      id="phonenumber"
                      type="text"
                      v-model="singleBusiness.phoneNo"
                      placeholder="Enter Phone Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="">Company Landline</label
                    ><input
                      class="form-control"
                      id="phonenumber"
                      type="text"
                      v-model="singleBusiness.landLine"
                      disabled
                      placeholder="Enter Landline Number"
                    />
                  </div>

                  <div class="form-group col-md-2">
                    <label for="">Company LGA</label
                    ><input
                      type="text"
                      disabled
                      v-model="singleBusiness.lga"
                      class="form-control"
                      id="lga"
                    />
                  </div>

                  <div class="form-group col-md-10">
                    <label for="">Company Address</label
                    ><input
                      class="form-control"
                      id="address"
                      type="text"
                      v-model="singleBusiness.address"
                      placeholder="Enter Address"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Company's Date Of Incorporation</label
                    ><input
                      class="form-control"
                      id="dateofbirth"
                      type="text"
                      v-model="singleBusiness.dateOfBirth.split('T')[0]"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Company Partner Name</label
                    ><input
                      class="form-control"
                      id="tin"
                      type="text"
                      v-model="singleBusiness.companyPartnerName"
                      placeholder="Company Partner Name"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Company RC Number</label
                    ><input
                      class="form-control"
                      id="tin"
                      type="text"
                      v-model="singleBusiness.meansOfId"
                      placeholder="Enter RC Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Company TIN</label
                    ><input
                      class="form-control"
                      id="tin"
                      type="text"
                      v-model="singleBusiness.tin"
                      placeholder="Enter TIN"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Company Occupation</label>
                    <input
                      v-model="singleBusiness.occupation"
                      disabled
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Marketing Staff</label
                    ><input
                      v-model="singleBusiness.mktStaff"
                      disabled
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="">Policy Start Date</label
                    ><input
                      class="form-control"
                      id="startdate"
                      type="text"
                      :value="
                        singleBusiness.startDate != ''
                          ? singleBusiness.startDate.split('T')[0]
                          : ''
                      "
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Policy Duration (In Days)</label
                    ><input
                      class="form-control"
                      id="duration"
                      type="number"
                      v-model="singleBusiness.duration"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Shipment Going From</label
                    ><input
                      class="form-control"
                      id="fromaddress"
                      type="text"
                      v-model="singleBusiness.from"
                      placeholder="Enter From Address"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Shipment Going To</label
                    ><input
                      class="form-control"
                      id="toaddress"
                      type="text"
                      v-model="singleBusiness.to"
                      placeholder="Enter To Address"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Conveyance</label
                    ><input
                      class="form-control"
                      id="conveyance"
                      type="text"
                      v-model="singleBusiness.conveyance"
                      placeholder="Enter To Address"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Cover Scope</label
                    ><input
                      class="form-control"
                      id="coverScope"
                      type="text"
                      v-model="singleBusiness.coverScope"
                      placeholder="Enter To Address"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Nature Of Cargo</label
                    ><input
                      class="form-control"
                      id="natureofCargo"
                      type="text"
                      v-model="singleBusiness.natureofCargo"
                      placeholder="Enter To Address"
                      disabled
                    />
                  </div>

                  <!-- <div class="form-group col-md-4">
                    <label for="">Invoiced Value</label
                    ><input
                      class="form-control"
                      id="invoicedValue"
                      type="text"
                      v-model="singleBusiness.invoicedValue"
                      placeholder="Enter Invoiced Value"
                      disabled
                    />
                  </div> -->

                  <div
                    v-if="singleBusiness.coverScope === 'MARINE OPEN COVER'"
                    class="form-group col-md-12"
                  >
                    <label for="">Selected Cover Types</label>
                    <div class="row mx-2">
                      <div
                        v-for="type in singleBusiness.addClauseOpenCover"
                        :key="type.id"
                        class="form-check my-2 col-md-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="singleBusiness.addClauseOpenCover"
                          :value="type"
                          :id="type"
                          disabled
                        />
                        <label class="form-check-label" :for="type">
                          {{ type }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Sum Insured</label
                    ><input
                      class="form-control"
                      id="suminsured"
                      type="number"
                      v-model="singleBusiness.sumInsured"
                      placeholder="Enter Sum Insured"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Premium Rate</label
                    ><input
                      class="form-control"
                      id="premiumrate"
                      type="number"
                      v-model="singleBusiness.premiumRate"
                      placeholder="Enter Premium Rate"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Premium</label
                    ><input
                      class="form-control"
                      id="premium"
                      type="number"
                      v-model="singleBusiness.premium"
                      placeholder="Enter Premium"
                      disabled
                    />
                  </div>

                  <div
                    v-if="singleBusiness.coverScope === 'MARINE SINGLE TRANSIT'"
                    class="form-group col-md-4"
                  >
                    <label for="">Type Of Cover</label
                    ><input
                      class="form-control"
                      type="text"
                      v-model="singleBusiness.typeofCover"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Endorsement</label
                    ><input
                      class="form-control"
                      id="endorsement"
                      type="text"
                      v-model="singleBusiness.endorsement"
                      placeholder="Enter Endorsement"
                      disabled
                    />
                  </div>

                  <!-- <div class="form-group col-md-4">
                      <label for="">Endorsement Option</label
                      ><input
                        class="form-control"
                        id="endorsementOptions"
                        type="text"
                        v-model="singleBusiness.endorsementOptions"
                        placeholder="Enter Endorsement"
                        disabled
                      />
                    </div> -->

                  <div class="form-group col-md-4">
                    <label for="">Voyage Type</label
                    ><input
                      class="form-control"
                      id="voyageType"
                      type="text"
                      v-model="singleBusiness.voyageType"
                      placeholder="Enter voyageType"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Parking Type</label
                    ><input
                      class="form-control"
                      id="ParkingType"
                      type="text"
                      v-model="singleBusiness.parkingType"
                      placeholder="Enter ParkingType"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Certificate Type</label
                    ><input
                      class="form-control"
                      id="certificateType"
                      type="text"
                      v-model="singleBusiness.certificateType"
                      placeholder="Enter certificateType"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Excess</label
                    ><input
                      class="form-control"
                      id="excess"
                      type="text"
                      v-model="singleBusiness.excess"
                      placeholder="Enter Excess"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Proformal Invoice Number</label
                    ><input
                      class="form-control"
                      id="proformalinvoice"
                      type="text"
                      v-model="singleBusiness.proformaInvoiceNo"
                      placeholder="Enter Proformal Invoice Number"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="">Vessel</label
                    ><input
                      class="form-control"
                      id="vessel"
                      type="text"
                      v-model="singleBusiness.vessel"
                      placeholder="Enter Vessel"
                      disabled
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label for="">Cargo Description (Detailed)</label
                    ><textarea
                      class="form-control"
                      v-model="singleBusiness.cargoDescription"
                      id="cargodescription"
                      rows="3"
                      disabled
                    ></textarea>
                  </div>

                  <div
                    v-if="singleBusiness.addClause.length != 0"
                    class="form-group col-md-6"
                  >
                    <label for="">Additional Clauses</label>
                    <div
                      v-for="clause in singleBusiness.addClause"
                      :key="clause"
                      class="form-check my-2"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked
                        :id="clause"
                        disabled
                      />
                      <label class="form-check-label" :for="clause">
                        {{ clause }}
                      </label>
                    </div>
                  </div>
                  <div
                    v-if="
                      singleBusiness.coverScope == 'MARINE SINGLE TRANSIT' &&
                      singleBusiness.tranrsactionreference != null &&
                      singleBusiness.tranrsactionreference != ''
                    "
                    class="form-group col-md-6"
                  >
                    <img
                      class="float-right mt-2"
                      style="width: 150px"
                      src="@/assets/paid.png"
                    />
                  </div>
                </div>
              </div>
              <button
                v-if="
                  singleBusiness.coverScope == 'MARINE SINGLE TRANSIT' &&
                  singleBusiness.tranrsactionreference != null &&
                  singleBusiness.tranrsactionreference != ''
                "
                @click.prevent="
                  printDiv(
                    singleBusiness.coverScope == 'MARINE SINGLE TRANSIT'
                      ? 'certificate2'
                      : singleBusiness.coverScope == 'MARINE OPEN COVER'
                      ? 'certificate'
                      : ''
                  )
                "
                class="btn btn-primary"
              >
                Print Certificate
              </button>
              <button
                v-if="
                  singleBusiness.tranrsactionreference != null &&
                  singleBusiness.tranrsactionreference != ''
                "
                @click.prevent="
                  printDiv(
                    singleBusiness.coverScope == 'MARINE SINGLE TRANSIT'
                      ? 'singletransitpolicy'
                      : singleBusiness.coverScope == 'MARINE OPEN COVER'
                      ? 'opencoverpolicy'
                      : ''
                  )
                "
                class="btn btn-primary"
                :class="[
                  singleBusiness.coverScope == 'MARINE SINGLE TRANSIT'
                    ? 'mx-3'
                    : '',
                ]"
              >
                Print Policy Document
              </button>
              <!-- <button
                v-if="singleBusiness.coverScope == 'MARINE OPEN COVER'"
                class="btn btn-primary mx-lg-3 my-3"
                data-toggle="modal"
                data-target="#additionalCertificate"
              >
                Buy Additional Certificate
              </button> -->
              <div
                v-if="
                  (singleBusiness.coverScope == 'MARINE SINGLE TRANSIT' &&
                    singleBusiness.tranrsactionreference == null) ||
                  singleBusiness.tranrsactionreference == ''
                "
              >
                <button
                  @click.prevent="pay()"
                  class="btn btn-primary float-right"
                >
                  Make Payment
                </button>
              </div>

              <!-- <button v-else class="btn disabled btn-success float-right">
                  Paid
                </button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <AdditionalCertificate
        v-if="!pageLoading && singleBusiness.coverScope == 'MARINE OPEN COVER'"
        :singleBusiness="singleBusiness"
      /> -->
    <BulkPayment
      v-if="!pageLoading && singleBusiness.coverScope == 'MARINE OPEN COVER'"
      :certificates="unpaidCertiifcates"
      :singleBusiness="singleBusiness"
    />
    <Certificate
      v-if="print"
      :singleBusiness="
        singleBusiness.coverScope == 'MARINE OPEN COVER'
          ? singleBusinessOpenCover
          : singleBusiness
      "
    />
    <Certificate2 v-if="!pageLoading" :singleBusiness="singleBusiness" />
    <SingleTransitPolicyDocument
      v-if="!pageLoading"
      :singleBusiness="singleBusiness"
    />
    <OpenCoverPolicyDocument
      v-if="!pageLoading"
      :singleBusiness="singleBusiness"
    />
    <!-- <div
        v-if="!pageLoading && singleBusiness.coverScope == 'MARINE OPEN COVER'"
        class="container-fluid"
      >
        <div class="card mb-4">
          <div class="card-header">
            All Certificates

            <button
              class="btn btn-primary ml-auto"
              data-toggle="modal"
              data-target="#bulkPayment"
              v-if="unpaidCertiifcates.length > 1"
            >
              Bulk Payment
            </button>
            <button v-else class="btn btn-primary disabled ml-auto" disabled>
              Bulk Payment
            </button>
          </div>
          <div class="card-body">
            <div class="datatable table-responsive">
              <table
                class="table table-bordered table-hover"
                id="dataTable"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Policy Number</th>
                    <th>Certificate Number</th>
                    <th>From</th>
                    <th>To</th>

                    <th>Print</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Policy Number</th>
                    <th>Certificate Number</th>
                    <th>From</th>
                    <th>To</th>

                    <th>Print</th>
                    <th>Payment</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr
                    v-for="additional in singleBusiness.additionalMarine"
                    :key="additional.insuredId"
                  >
                    <td>{{ singleBusiness.policyno }}</td>
                    <td>{{ additional.certificateNo }}</td>
                    <td>{{ additional.from }}</td>
                    <td>{{ additional.to }}</td>

                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="printDiv('certificate', additional)"
                      >
                        Print Certificate
                      </button>
                    </td>
                    <td>
                      <button
                        @click.prevent="pay(additional)"
                        v-if="
                          additional.tranrsactionreference == '' ||
                          additional.tranrsactionreference == null
                        "
                        type="button"
                        class="btn btn-primary"
                      >
                        Make Payment
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success disabled"
                        disabled
                      >
                        Paid
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
  </main>
</template>

<style>
.disabled:hover {
  cursor: not-allowed;
}
</style>

<script>
import Certificate from "../../../components/marine/Certificate.vue";
import Certificate2 from "../../../components/marine/Certificate2.vue";
import SingleTransitPolicyDocument from "../../../components/marine/PolicyDocument1.vue";
import OpenCoverPolicyDocument from "../../../components/marine/PolicyDocument2.vue";
// import AdditionalCertificate from "@/components/marine/AdditionalCertificate";
import { mapActions, mapState } from "pinia";
import { useMarineStore } from "../../../stores/marine";
import { useMainStore } from "../../../stores/main";
import { $savePdf, print } from "../../../helpers";
import $config from "../../../helpers/config";
import BulkPayment from "../../../components/marine/BulkPayment.vue";

export default {
  name: "Single Business",
  components: {
    Certificate,
    Certificate2,
    SingleTransitPolicyDocument,
    OpenCoverPolicyDocument,
    // AdditionalCertificate,
    BulkPayment,
  },
  data() {
    return {
      singleBusinessOpenCover: {},
      tranrsactionreference: "",
      print: false,
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "loading",
      "pageLoading",
      "singleBusiness",
      "unpaidCertiifcates",
    ]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getBookedBusinesses",
      "getBusiness",
      "getSingleBusiness",
      "makePayment",
    ]),

    async printDiv(divName, additional) {
      if (divName == "certificate") {
        this.print = true;
        this.singleBusinessOpenCover = {
          ...this.singleBusiness,
          ...additional,
        };
        this.singleBusinessOpenCover.certifateNo = additional.certificateNo;
        if (this.singleBusinessOpenCover.premiumRate == 0)
          this.singleBusinessOpenCover.premiumRate =
            this.singleBusiness.premiumRate;
        this.singleBusinessOpenCover.certifateNo = additional.certificateNo;
        setTimeout(() => {
          const printContents = document.getElementById(divName).innerHTML;
          print(printContents);
        }, 10);
      } else {
        const printContents = document.getElementById(divName).innerHTML;
        print(printContents);
      }
    },

    saveCert(divName) {
      const printContents = document.getElementById(divName).innerHTML;
      $savePdf.save(printContents, this.singleBusiness.certifateNo);
    },

    async flwPay(policy) {
      //eslint-disable-next-line no-undef
      FlutterwaveCheckout({
        public_key: $config.flwLivePublicKey,
        tx_ref: this.tranrsactionreference,
        amount: policy.premium,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        callback: () => {
          this.makePayment({
            certificateNo: [policy.certifateNo],
            startDate: policy.startDate,
            policyno: policy.policyno,
            coverScope: policy.coverScope,
            sumInsured: policy.sumInsured,
            premium: policy.premium,
            premiumRate: policy.premiumRate,
            endorsement: policy.endorsement,
            submittedBy: "Marine Portal",
            transactionReference: this.tranrsactionreference,
          });
        },
        meta: {
          broker_id: this.user.brokerId,
        },
        customer: {
          email: this.user.email,
          name: this.user.broker,
        },
        customizations: {
          title: "Consolidated Hallmark Insurance",
          description: "Payment for marine insurance",
          logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
        },
      });
    },

    async pay(additional) {
      this.tranrsactionreference = `CHIMARINETXR-${Math.floor(
        Math.random() * 100000000
      )}`;

      if (this.singleBusiness.coverScope == "MARINE OPEN COVER" && additional) {
        this.singleBusinessOpenCover = {
          ...this.singleBusiness,
          ...additional,
        };
        this.singleBusinessOpenCover.certifateNo = additional.certificateNo;
        this.singleBusinessOpenCover.coverScope = "MARINE OPEN COVER";

        this.flwPay(this.singleBusinessOpenCover);
      } else this.flwPay(this.singleBusiness);
    },
  },

  async created() {
    // if (this.user.role !== "Agent") {
    //   this.$router.go(-1);
    // }
    //await this.getBookedBusinesses();
    await this.getSingleBusiness(this.$route.params.id);
  },

  mounted() {},
};
</script>
